import React, { useEffect, useMemo } from 'react'
import _values from 'lodash/values'
import { setConfiguration, ScreenClassProvider } from 'react-grid-system'
import PropTypes from 'prop-types'
import { ThemeProvider as StyledThemeProvider } from 'styled-components'

import { Global } from './global'
import themes from './themes'

const ThemeProvider = ({ variant, children }) => {
  const theme = useMemo(() => {
    return themes[variant]
  }, [variant])

  useEffect(() => {
    setConfiguration({
      breakpoints: _values(theme.utils.grid.breakpoints),
      containerWidths: _values(theme.utils.grid.containerWidths),
      gutterWidth: theme.utils.grid.gutterWidth,
    })
  }, [theme])

  return (
    <StyledThemeProvider theme={theme}>
      <ScreenClassProvider>
        <Global />
        {children}
      </ScreenClassProvider>
    </StyledThemeProvider>
  )
}

ThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
  variant: PropTypes.string,
}

ThemeProvider.defaultProps = {
  variant: 'light',
}

export { AvailableColors } from './palette'
export { breakpoint } from './utils'

export default ThemeProvider
