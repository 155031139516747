import React from 'react'
import { graphql } from 'gatsby'
import { InfoBarConfigFragment } from '../../generated/graphql-operations'
import { Bar, BarInner, BarText, BarButton } from './InfoBarStyles'
import Link from 'widgets/Link'
import { useGetInternalLink } from 'utils/helpers'

export const query = graphql`
  fragment InternalLink on SanityInternalLink {
    reference {
      ...InternalLinkReference
    }
    eventCategory
  }
  fragment InternalLinkReference on SanityNewsOrPageFileOrProgramOrRoute {
    __typename
    ... on SanityDocument {
      _id
      _type
    }

    ... on SanityNews {
      newsSlug: slug(lang: $language)
    }

    ... on SanityRoute {
      routeSlug: slug {
        current
      }
    }

    ... on SanityProgram {
      programSlug: slug
    }

    ... on SanityPageFile {
      file {
        asset {
          url
          originalFilename
        }
      }
    }
  }

  fragment InfoBarConfig on SanitySiteConfig {
    infoBar {
      active
      text {
        localized(locale: $language)
      }
      ctaLabel {
        localized(locale: $language)
      }
      target {
        ...InternalLink
      }
    }
  }
`

type InfoBarProps = {
  config: InfoBarConfigFragment | undefined
  hideMobile: boolean
}
const InfoBar: React.FC<InfoBarProps> = ({ config, hideMobile }) => {
  const infoBar = config?.infoBar

  const link = useGetInternalLink(
    infoBar?.target?.reference ?? undefined,
    undefined,
    undefined,
    infoBar?.target?.eventCategory
  )

  if (!infoBar || !infoBar.active) {
    return null
  }

  return (
    <Bar $hideMobile={hideMobile}>
      <BarInner>
        <BarText>{infoBar.text?.localized}</BarText>
        {link && !link.is404 && (
          <BarButton as={Link} to={link}>
            {infoBar.ctaLabel?.localized}
          </BarButton>
        )}
      </BarInner>
    </Bar>
  )
}

export default InfoBar
