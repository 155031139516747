import React, { ComponentProps } from 'react'

export const lazyload = (imp: Parameters<typeof React.lazy>[0]) => {
  const Comp = React.lazy(imp)

  const Render: React.FC<ComponentProps<typeof Comp>> = (props) => {
    return (
      <React.Suspense>
        <Comp {...props} />
      </React.Suspense>
    )
  }

  return Render
}
