import React from 'react'
import styled from 'styled-components'
import { breakpoint } from 'ui/elements/ThemeProvider'
import { StaticImage } from 'gatsby-plugin-image'

const SAccreditationLogos = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  ${breakpoint.lg`
    justify-content: flex-end;
  `};

  [data-gatsby-image-wrapper] {
    width: 100%;
    max-width: 50px;
    height: auto;
    margin-left: 30px;

    &:first-child {
      margin-left: 0;
    }
  }
`

const AccreditationLogos = () => {
  return (
    <SAccreditationLogos>
      <StaticImage
        src="../../images/accreditations/equis.png"
        alt="EQUIS"
        width={50}
      />
      <StaticImage
        src="../../images/accreditations/aacsb.png"
        alt="AACSB"
        width={50}
      />
      <StaticImage
        src="../../images/accreditations/fibaa.png"
        alt="FIBAA"
        width={50}
      />
    </SAccreditationLogos>
  )
}

export default AccreditationLogos
