import styled, { createGlobalStyle, css } from 'styled-components'
import { breakpoint } from '../ThemeProvider'
import RawBurger from './Burger'

import { Next } from './Icons'

export const Burger = styled(RawBurger)`
  margin-right: 12px;

  ${breakpoint.lg(
    () => css`
      display: none;
    `
  )}
`

type NavProps = {
  $open: boolean
}

const NavOpen = css`
  display: block;

  position: fixed;
  top: 50px;
  bottom: 0;
  left: 0;

  width: 100%;

  background: white;

  overflow-y: auto;
  overflow-x: hidden;
  scroll-behavior: smooth;
`

const NavMobile = css<NavProps>`
  ${(props) =>
    !props.$open &&
    css`
      display: none;
    `}

  ${(props) => props.$open && NavOpen}
`

const NavDesktop = css`
  height: 100%;
`

export const Nav = styled.nav<NavProps>`
  ${breakpoint.max_lg(() => NavMobile as any)}
  ${breakpoint.lg(() => NavDesktop)}
`

export const Entries = styled.ul`
  display: flex;
  flex-direction: column;

  list-style-type: none;

  margin: 0;
  padding: 0;

  ${breakpoint.lg(
    () => css`
      flex-direction: row;

      height: 100%;
    `
  )}
`

export const MenuItemButton = css`
  position: relative;

  &:before,
  &:after {
    content: '';

    display: inline-block;
    flex-shrink: 0;
    flex-grow: 0;

    height: 50px;
    width: 50px;
  }

  &:after {
    margin-left: auto;
    border-left: 1px solid ${(props) => props.theme.palette.paperGamma};
  }

  &:before {
    position: absolute;

    right: 0;

    background-image: url('${(props) => Next(props.theme.palette.paperGamma)}');
    background-repeat: no-repeat;
    background-size: 20px 20px;
    background-position: center;
  }
`

export const MenuItemMobile = css<{
  $open?: boolean
  $hasChildren?: boolean
  $topLevel?: true
}>`
  display: flex;
  width: 100%;

  align-content: center;
  align-items: center;

  padding-left: 20px;

  cursor: pointer;
  color: inherit;
  text-decoration: none;

  border-bottom: 1px solid ${(props) => props.theme.palette.paperGamma};

  min-height: 50px;

  font-weight: ${(props) => (props.$open ? 'bold' : 'initial')};

  ${(props) =>
    props.$hasChildren &&
    css<{
      $open?: boolean
      $topLevel?: true
    }>`
      // this rule needs to be located before the mixin
      // otherwise it will be transformed to &:before::before ...
      &:before {
        ${(props) =>
          !props.$topLevel &&
          css`
            transform: rotate(90deg) !important;
          `}

        ${(props) =>
          props.$open &&
          css`
            transform: rotate(-90deg) !important;
          `}
      }

      ${MenuItemButton};
    `}

  ${(props) =>
    !props.$hasChildren &&
    css`
      padding: 10px 50px 10px 20px;
    `}
`

export const MenuItemDesktop = css`
  display: flex;
  height: 100%;
  width: 100%;

  align-items: center;
  justify-items: center;

  cursor: pointer;
  color: inherit;
  text-decoration: none;

  &:visited {
    color: inherit;
  }

  &:hover,
  &:focus,
  &:active {
    color: ${(props) => props.theme.palette.primary};
  }
`
export const EntryLink = styled.span``

export const EntryItemWrap = styled.span<{
  $open?: boolean
  $hasChildren?: boolean
  $topLevel?: true
}>`
  display: flex;
  height: 100%;

  ${breakpoint.lg(
    () => css`
      padding: 0 10px;
    `
  )}

  > ${EntryLink} {
    ${breakpoint.lg(() => MenuItemDesktop)};
    ${breakpoint.max_lg(() => MenuItemMobile)};
  }
`

export const SubMenusDropdownMobile = css<{
  $open: boolean
}>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background: white;
  z-index: 10;

  transition: transform 0.3s;
  transform: translateX(${(props) => (props.$open ? 0 : '100%')});
`

export const SubMenusDropdownDesktop = css<{
  $open: boolean
  $height: number | undefined
}>`
  position: absolute;

  transition: opacity 0.25s;

  top: 94px;
  left: 0;
  right: 0;

  width: 100%;

  background: #fff;
  box-shadow: 0 2px 4px -2px rgba(0, 0, 0, 0.22);

  z-index: 2;

  ${(props) =>
    !props.$open &&
    css`
      opacity: 0;
      pointer-events: none;
    `}
`

export const SubMenusDropdown = styled.div<{
  $open: boolean
  $height: number | undefined
}>`
  ${breakpoint.max_lg(() => SubMenusDropdownMobile as any)}
  ${breakpoint.lg(() => SubMenusDropdownDesktop as any)}
`

export const SubMenus = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;

  ${breakpoint.lg(
    () => css`
      display: flex;
      flex-wrap: wrap;

      max-width: 1170px;

      margin: 0 auto;
    `
  )}
`

export const BodyNoScroll = createGlobalStyle<{
  $open: boolean
}>`
  html, body {
    ${(props) =>
      props.$open &&
      css`
        ${breakpoint.max_lg(
          () => css`
            overflow: hidden;
          `
        )}
      `}
  }
`

export const Entry = styled.li`
  display: block;
`
