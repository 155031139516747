import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import AppBar from 'ui/elements/AppBar'
import {
  MenuContextProvider,
  useMenuContext,
} from 'ui/elements/Menu/MenuContext'
import { SrOnly } from 'ui/elements/Text/styles'
import debounce from 'lodash/debounce'

import Navigation from '../Navigation'

import * as S from './Header.styles'
import { useTranslation } from 'gatsby-plugin-react-i18next'

const IdentityComponent: React.FC = () => {
  const [t] = useTranslation('common')
  const { subMenuOpen, resetSubMenu, menuTitle } = useMenuContext()

  return (
    <>
      <S.IdentityWrap $open={subMenuOpen}>
        <S.Link to="/">
          <S.Identity width={290} height={74} />
          <SrOnly>{t('goToIndex')}</SrOnly>
        </S.Link>
        <S.Back onClick={resetSubMenu} />
        <S.MenuTitle>{menuTitle}</S.MenuTitle>
      </S.IdentityWrap>
    </>
  )
}

const Header: React.FC<{
  hasNavigation?: boolean
  onMenuOpen?: (state: boolean) => void
}> = ({ hasNavigation, onMenuOpen }) => {
  const elRef = useRef<HTMLDivElement | null>(null)
  const [height, _setHeight] = useState<number | undefined>(undefined)

  const handleSize = useMemo(
    () =>
      debounce(() => {
        const elem = elRef.current

        if (!elem) {
          return
        }

        const rect = elem.getBoundingClientRect()

        _setHeight(rect.top + rect.height)
      }, 500),
    []
  )

  const getRef = useCallback(
    (element: HTMLDivElement | null) => {
      elRef.current = element

      handleSize()
    },
    [handleSize]
  )

  useEffect(() => {
    if (!window) {
      return
    }

    window.addEventListener('resize', handleSize)

    return () => {
      window.removeEventListener('resize', handleSize)
    }
  }, [handleSize])

  return (
    <MenuContextProvider>
      <AppBar
        ref={getRef}
        left={<IdentityComponent />}
        right={
          <Navigation
            searchStart={height}
            onMenuOpen={onMenuOpen}
            hasNavigation={hasNavigation}
          />
        }
      />
    </MenuContextProvider>
  )
}

export default Header
