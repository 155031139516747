import styled, { css } from 'styled-components'
import type { SubMenu } from './Menu'
import { breakpoint } from '../ThemeProvider/utils'
import { MenuItemMobile } from './Menu.styles'

// @ts-ignore
import Icon from '-!url-loader!../Icon/icons/next.svg'

export const Title = styled.span<{
  $open?: boolean
  $hasChildren?: boolean
}>`
  display: block;

  ${breakpoint.lg(
    () => css`
      font-weight: bold;
      font-size: 1.4em;
      line-height: 1;

      text-decoration: none;
      cursor: text;
    `
  )}

  ${breakpoint.max_lg(() => MenuItemMobile)}
`
export const SubTitle = styled.span`
  display: none;

  line-height: 1;

  margin-top: 0.75em;
  margin-bottom: 1.25em;

  ${breakpoint.lg(
    () => css`
      display: block;
    `
  )}
`

export const IconWrapper = styled.div`
  display: none;

  width: 124px;
  flex-shrink: 0;

  padding-right: 24px;

  ${breakpoint.lg(
    () => css`
      display: block;
    `
  )}
`

export const ContentWrapper = styled.div`
  border: none;

  ${breakpoint.lg(
    () => css`
      border-left: 2px solid transparent;
      padding-left: 18px;
    `
  )}
`

export const ItemsWrap = styled.div``
export const Items = styled.ul<{
  $open: boolean
}>`
  display: ${(props) => (props.$open ? 'inline-block' : 'none')};

  width: 100%;

  list-style-type: none;
  padding: 0;
  margin: 0;

  ${breakpoint.lg(
    () => css`
      display: inline-block;
    `
  )}
`

export const SubItems = styled.ul<{
  $open: boolean
}>`
  list-style-type: none;
  padding: 0;
  margin: 0;

  display: ${(props) => (props.$open ? 'block' : 'none')};

  ${breakpoint.lg(
    () => css`
      position: absolute;
      top: 0;
      left: 100%;

      white-space: nowrap;

      background: ${(props) => props.theme.palette.paperGamma};

      z-index: 3;
    `
  )}
`

const ItemLinkDesktop = css`
  display: flex;
  align-content: center;
  align-items: center;
  vertical-align: center;

  padding: 10px 18px;

  color: #000;
  text-decoration: none;

  cursor: pointer;

  &:after {
    display: inline-block;
    content: '';

    width: 10px;
    height: 10px;

    background-image: url(${Icon});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;

    margin: auto 0 auto 5px;
  }
`

export const ItemLink = styled.span<{
  $open?: boolean
  $hasChildren?: boolean
  $palette?: SubMenu['palette']
}>`
  ${breakpoint.lg(() => ItemLinkDesktop)}
  ${breakpoint.max_lg(
    () => css<{
      $open?: boolean
      $palette?: SubMenu['palette']
    }>`
      line-height: 1.25;

      ${(props) =>
        props.$open &&
        css<{
          $palette?: SubMenu['palette']
        }>`
          color: ${(props) =>
            props.theme.palette?.[props.$palette?.accent ?? ''] ??
            'inherit'} !important;
        `};

      ${MenuItemMobile};
    `
  )}
`

export const Item = styled.li`
  position: relative;

  ${breakpoint.lg(
    () => css`
      cursor: pointer;

      &:hover {
        background: ${(props) => props.theme.palette.paperGamma};

        ${SubItems} {
          display: block;
        }

        > ${ItemLink} {
          color: ${(props) => props.theme.palette.secondaryAlpha};
        }
      }
    `
  )}
`

const WrapperDesktop = css<{
  $palette: SubMenu['palette']
}>`
  display: flex;
  width: 50%;
  margin: 2em auto;

  ${Title}, ${SubTitle} {
    color: ${(props) =>
      props.theme.palette?.[props.$palette?.accent ?? ''] ?? 'inherit'};
  }

  ${ContentWrapper} {
    border-left-color: ${(props) =>
      props.theme.palette?.[props.$palette?.variant ?? ''] ?? 'inherit'};
  }
`

const WrapperMobile = css<{
  $palette: SubMenu['palette']
}>`
  ${Title} {
    border-left: 2px solid
      ${(props) =>
        props.theme.palette?.[props.$palette?.variant ?? ''] ?? 'inherit'};
  }

  ${ItemLink} {
    border-left: 4px solid
      ${(props) =>
        props.theme.palette?.[props.$palette?.variant ?? ''] ?? 'inherit'};
  }
`

export const Wrapper = styled.li<{
  $palette: SubMenu['palette']
}>`
  ${breakpoint.max_lg(() => WrapperMobile as any)};
  ${breakpoint.lg(() => WrapperDesktop as any)}
`

export const ButtonWrap = styled.div`
  display: none;

  margin-top: 1.5em;

  ${breakpoint.lg(
    () => css`
      display: block;
    `
  )}
`
