import Helvetica400Woff2 from './HelveticaNeueLTProLt/font.woff2'
import Helvetica400Woff from './HelveticaNeueLTProLt/font.woff'
import Helvetica500Woff2 from './HelveticaNeueLTProRoman/font.woff2'
import Helvetica500Woff from './HelveticaNeueLTProRoman/font.woff'
import Helvetica600Woff2 from './HelveticaNeueLTProBd/font.woff2'
import Helvetica600Woff from './HelveticaNeueLTProBd/font.woff'

const fontMap = {
  helvetica400: {
    woff2: Helvetica400Woff2,
    woff: Helvetica400Woff,
  },
  helvetica500: {
    woff2: Helvetica500Woff2,
    woff: Helvetica500Woff,
  },
  helvetica600: {
    woff2: Helvetica600Woff2,
    woff: Helvetica600Woff,
  },
}

export default fontMap
