import { trackingConfig } from '../../trackingConfig'
import { insertScript } from './trackingHelper'

declare global {
  interface Window {
    _linkedin_data_partner_ids: any[]
  }
}

let loaded = false

export const loadLinkedIn = () => {
  if (loaded) {
    return
  }

  loaded = true

  const partnerId = trackingConfig.linkedinInsight.partnerId
  window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || []
  window._linkedin_data_partner_ids.push(partnerId)

  insertScript('https://snap.licdn.com/li.lms-analytics/insight.min.js')
}
