import React, { ComponentProps, HTMLProps, useCallback, useMemo } from 'react'
import { Link as GatsbyLink, useI18next } from 'gatsby-plugin-react-i18next'
import PropTypes from 'prop-types'
import { GatsbyLinkProps } from 'gatsby'

export const useNavigation = () => {
  const { navigate } = useI18next()

  return navigate
}

type ToType =
  | {
      type: 'internal'
      to: string
      onClick?: HTMLProps<HTMLAnchorElement>['onClick']
    }
  | {
      type: 'external'
      href: string
      onClick?: HTMLProps<HTMLAnchorElement>['onClick']
    }

const Link: React.FC<
  Omit<GatsbyLinkProps<any>, 'ref' | 'to'> & {
    to: string | ToType
  }
> = ({ to: _to, children, onClick: _onClick, ...props }) => {
  const to = useMemo<ToType>(() => {
    if (!_to || typeof _to === 'string') {
      return {
        type: 'internal',
        to: _to,
        onClick: undefined,
      }
    }

    return _to
  }, [props])

  const handleClick = useCallback<
    NonNullable<HTMLProps<HTMLAnchorElement>['onClick']>
  >(
    (...args) => {
      if (to.onClick) {
        to.onClick(...args)
      }

      if (_onClick) {
        _onClick(...args)
      }
    },
    [to.onClick, _onClick]
  )

  const toWithSlash = useMemo(() => {
    if (to.type === 'external' || !to.to) {
      return undefined
    }

    if (to.to.slice(-1) === '/') {
      return to.to
    }

    return `${to.to}/`
  }, [to])

  if (to.type === 'external') {
    return (
      <a href={to.href} onClick={handleClick} {...props}>
        {children}
      </a>
    )
  }

  return (
    <GatsbyLink to={toWithSlash!} onClick={handleClick} {...props}>
      {children}
    </GatsbyLink>
  )
}
export default Link
