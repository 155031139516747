import { insertScript } from './trackingHelper'
import { trackingConfig } from '../../trackingConfig'

declare global {
  interface Window {
    fbq: any
    _fbq: any
  }
}

let added = false

export const trackFacebook = (action: string, event: string) => {
  if (!added || !window.fbq) {
    return
  }

  window.fbq(action, event)
}

export const loadFacebook = () => {
  if (window.fbq || added) {
    added = true
    return
  }

  added = true

  const n: any = (window.fbq = function () {
    n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments)
  })

  if (!window._fbq) window._fbq = n
  n.push = n
  n.loaded = !0
  n.version = '2.0'
  n.queue = []

  window.fbq('init', trackingConfig.facebookPixel.pixelId)

  insertScript('https://connect.facebook.net/en_US/fbevents.js')

  window.fbq('track', 'PageView')
}
