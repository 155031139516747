import styled, { css } from 'styled-components'
import { Button } from 'ui/elements/Button/styles'
import { breakpoint } from 'ui/elements/ThemeProvider'

export const Bar = styled.div`
  display: block;
  background-color: ${(props) => props.theme.palette.paperBeta};
  color: ${(props) => props.theme.palette.gunmetal};
`
export const BarInner = styled.div`
  display: block;
  max-width: 1170px;
  margin: auto;

  padding: 15px 15px;
  box-sizing: border-box;

  ${breakpoint.md(
    () => css`
      padding: 3rem 15px;
    `
  )}
`
export const Headline = styled.span`
  display: block;
  font-weight: 300;
  font-size: 24px;

  ${breakpoint.md(
    () => css`
      font-size: 30px;
    `
  )}
`
export const ContentWrap = styled.div`
  display: block;
  align-items: center;

  ${Button} {
    flex-shrink: 0;
    margin-top: 15px;
  }

  ${breakpoint.md(
    () => css`
      display: flex;

      ${Button} {
        margin: auto;
      }
    `
  )}
`
export const Content = styled.span`
  display: none;

  ${breakpoint.md(
    () => css`
      display: block;
      padding-right: 3rem;
    `
  )}
`
