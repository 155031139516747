import { insertScript } from './trackingHelper'
import { trackingConfig } from '../../trackingConfig'

export interface EventArgs {
  /** Typically the object that was interacted with (e.g. 'Video') */
  category: string
  /** The type of interaction (e.g. 'play') */
  action: string
  /** Useful for categorizing events (e.g. 'Fall Campaign') */
  label?: string
  /** A numeric value associated with the event (e.g. 42) */
  value?: number
  /** Specifies that a hit be considered non-interactive. */
  nonInteraction?: boolean
  /**
   * This specifies the transport mechanism with which hits will be sent.
   * The options are 'beacon', 'xhr', or 'image'. By default, analytics.js
   * will try to figure out the best method based on the hit size and browser
   * capabilities. If you specify 'beacon' and the user's browser does not support
   * the `navigator.sendBeacon` method, it will fall back to 'image' or 'xhr'
   * depending on hit size.
   */
  transport?: 'beacon' | 'xhr' | 'image'

  [key: `dimension${number}`]: string
  [key: `metric${number}`]: string
}

declare global {
  interface Window {
    gtag?: (...args: unknown[]) => void
    dataLayer: unknown[][]
  }
}

let enabled = false

export const gtag = (...args: unknown[]) => {
  if (!enabled) {
    return
  }

  window.gtag && window.gtag(...args)
}

export const gtagPrepare = (...args: unknown[]) => {
  if (!enabled) {
    return
  }

  window.dataLayer = window.dataLayer || []

  window.dataLayer.push(args)
}

export const gaEvent = (_args: EventArgs) => {
  if (!enabled) {
    return
  }

  const { category, action, label, value, nonInteraction, ...args } = _args

  const fieldObject = Object.fromEntries(
    Object.entries({
      event_category: category,
      event_label: label,
      event_value: value,
      non_interaction: nonInteraction,
      ...args,
    }).filter(([, val]) => !!val)
  )

  gtag('event', action, fieldObject)
}

export const loadGoogleAnalytics = () => {
  if (enabled) {
    return
  }

  enabled = true

  window.dataLayer = window.dataLayer || []

  window.gtag =
    window.gtag ||
    function () {
      // @ts-ignore
      window.dataLayer.push(arguments)
    }

  gtag('js', new Date())

  gtag('config', trackingConfig.googleAnalytics.trackingId, {
    anonymize_ip: true,
    allow_google_signals: false,
  })

  insertScript(
    `https://www.googletagmanager.com/gtag/js?id=${trackingConfig.googleAnalytics.trackingId}`
  )
}

export const trackGoogleAnalyticsPageView = (location: Location) => {
  gtag('event', 'page_view', {
    page_path: location
      ? location.pathname + location.search + location.hash
      : undefined,
  })
}
