import styled, { css } from 'styled-components'
import BaseLink from 'widgets/Link'
import BaseIdentity from 'ui/elements/Identity'
import { MenuItemButton } from 'ui/elements/Menu/Menu.styles'
import { breakpoint } from 'ui/elements/ThemeProvider/utils'

export const Link = styled(BaseLink)`
  display: flex;
  height: 100%;
  width: 100%;

  flex-shrink: 0;
  flex-grow: 0;

  img {
    margin: auto auto auto 15px;

    width: auto;
    height: 100%;

    ${breakpoint.lg(
      () => css`
        margin-left: auto;

        max-width: 290px;
      `
    )}
  }
`
export const Identity = styled(BaseIdentity)`
  width: 100%;
  max-height: 100%;
  margin: auto 0;

  ${breakpoint.max_lg(css`
    padding: 10px 0;
  `)}
`

export const Back = styled.button`
  appearance: none;
  border: none;
  outline: none;
  margin: 0;
  padding: 0;

  flex-shrink: 0;
  flex-grow: 0;

  background: white;

  // this rule needs to be located before the mixin
  // otherwise it will be transformed to &:before::before ...
  &::before {
    transform: rotate(-180deg) !important;
  }

  ${MenuItemButton};
`

export const MenuTitle = styled.span`
  margin: auto 0 auto 15px;
  font-weight: bold;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const IdentityWrap = styled.div<{
  $open: boolean
}>`
  display: flex;
  height: 100%;

  ${Link} {
    display: ${(props) => (props.$open ? 'none' : 'block')};
  }

  ${Back} {
    display: ${(props) => (props.$open ? 'block' : 'none')};
  }

  ${breakpoint.lg(
    () => css`
      display: block;

      ${Link} {
        display: block;
        padding: 10px;
      }

      ${MenuTitle},
      ${Back} {
        display: none;
      }
    `
  )}
`

export const NavigationWrapper = styled.div`
  display: flex;
  > * {
    margin: 0 5px;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
`
