import styled, { css } from 'styled-components'

export const Text = styled.span`
  font-family: 'Helvetica Neue', 'Arial Regular', sans-serif;
  margin: 0;
  padding: 0;
  line-height: 1.2;
  font-weight: ${(props) => props.weight};
  color: ${(props) =>
    props.color === 'inherit' ? 'inherit' : props.theme.palette[props.color]};
  ${(props) =>
    props.colorLink
      ? css`
          a {
            color: ${props.color === 'inherit'
              ? 'inherit'
              : props.theme.palette[props.color]};
            text-decoration: none;
            font-weight: inherit;
          }
        `
      : undefined}
`

export const SrOnly = styled.span`
  display: none;
`
