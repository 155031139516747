import React, {
  MouseEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import type { MenuEntryType } from './Menu'
import {
  Entry,
  EntryItemWrap,
  SubMenus,
  SubMenusDropdown,
  EntryLink,
} from './Menu.styles'
import SubMenu from './SubMenu'
import { useMenuContext } from './MenuContext'
import { Link } from 'gatsby-plugin-react-i18next'
import { grid } from 'ui/elements/ThemeProvider/utils'

type SubMenuWrapProps = {
  id: MenuEntryType['id']
  submenus: NonNullable<MenuEntryType['submenus']>
}
const RenderSubMenu: React.FC<SubMenuWrapProps> = ({ id, submenus }) => {
  const { isSubMenuOpen } = useMenuContext()

  const open = useMemo(() => {
    return isSubMenuOpen([id])
  }, [isSubMenuOpen, id])

  const ref = useRef<HTMLDivElement | null>(null)
  const [height, setHeight] = useState<number | undefined>()
  const updateHeight = useCallback((instance: typeof ref['current']) => {
    ref.current = instance

    if (!instance) {
      return
    }

    setHeight(instance.offsetHeight)
  }, [])

  useEffect(() => {
    const cb = () => {
      updateHeight(ref.current)
    }

    window.addEventListener('resize', cb)

    return () => {
      window.removeEventListener('resize', cb)
    }
  }, [updateHeight])

  return (
    <SubMenusDropdown ref={updateHeight} $open={open} $height={height}>
      <SubMenus>
        {submenus.map((submenu) => (
          <SubMenu
            parent_id={id}
            submenu={submenu}
            key={`${id}-${submenu.id}`}
          />
        ))}
      </SubMenus>
    </SubMenusDropdown>
  )
}

type MenuEntryProps = {
  entry: MenuEntryType
}
const MenuEntry: React.FC<MenuEntryProps> = ({ entry }) => {
  const { id, title } = entry

  const {
    openSubMenu,
    closeSubMenu,
    toggleSubMenu,
    onNavigation,
    setMenuTitle,
  } = useMenuContext()
  const entryRef = useRef<HTMLSpanElement>(null)

  const clicked = useRef(false)

  const toggle = useCallback<MouseEventHandler>(
    (event) => {
      if (event.target instanceof Element && event.target.tagName === 'A') {
        // reset menu state
        onNavigation()
        return
      }

      const oldClickedValue = clicked.current
      clicked.current = !clicked.current

      if (oldClickedValue) {
        closeSubMenu([id])
        return
      }

      openSubMenu([id])
      setMenuTitle(title)
    },
    [id, title, onNavigation, toggleSubMenu, setMenuTitle]
  )

  const mouseOver = useCallback(() => {
    // only on desktop
    if (window.innerWidth < grid.breakpoints.lg) {
      return
    }

    openSubMenu([id])
  }, [openSubMenu])

  const onMouseOut = useCallback<MouseEventHandler<HTMLSpanElement>>(
    (event) => {
      event.preventDefault()

      if (clicked.current) {
        return
      }

      if (
        event.relatedTarget instanceof Node &&
        event.currentTarget.parentElement?.contains(event.relatedTarget)
      ) {
        return
      }

      closeSubMenu([id])
    },
    [closeSubMenu]
  )

  const onParentMouseOut = useCallback<MouseEventHandler<HTMLSpanElement>>(
    (event) => {
      if (
        event.relatedTarget instanceof Node &&
        event.currentTarget.contains(event.relatedTarget)
      ) {
        return
      }

      if (clicked.current) {
        return
      }

      closeSubMenu([id])
    },
    []
  )

  return (
    <Entry onMouseOut={onParentMouseOut}>
      <EntryItemWrap
        ref={entryRef}
        $hasChildren={entry.submenus && entry.submenus.length > 0}
        $topLevel
        onClick={toggle}
        onMouseEnter={mouseOver}
        onMouseOut={onMouseOut}
      >
        {entry.item && <>{entry.item}</>}
        {!entry.item && (
          <>
            {entry.href && (
              <EntryLink as={Link} to={entry.href}>
                <span>{entry.title}</span>
              </EntryLink>
            )}
            {!entry.href && (
              <EntryLink>
                <span>{entry.title}</span>
              </EntryLink>
            )}
          </>
        )}
      </EntryItemWrap>
      {entry.submenus && entry.submenus.length > 0 && (
        <RenderSubMenu id={id} submenus={entry.submenus} />
      )}
    </Entry>
  )
}

export default MenuEntry
