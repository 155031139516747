import React from 'react'

import * as S from './styles'

const AppBar = React.forwardRef<
  HTMLDivElement | null,
  {
    left?: React.ReactNode
    right?: React.ReactNode
  }
>(({ left, right }, ref) => {
  return (
    <S.AppBar ref={ref}>
      <S.RowContainer>
        <S.Row>
          <S.LeftContainer>{left}</S.LeftContainer>
          <S.RightContainer>{right}</S.RightContainer>
        </S.Row>
      </S.RowContainer>
    </S.AppBar>
  )
})

export { S as AppBarStyles }
export default AppBar
