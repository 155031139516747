import _reduce from 'lodash/reduce'

const capitalize = (s) => s[0].toUpperCase() + s.slice(1)

function mix(colors, prefix) {
  return _reduce(
    colors,
    (acc, value, name) => {
      if (typeof value === 'string') {
        const k = prefix ? `${prefix}${capitalize(name)}` : name
        return { ...acc, [k]: value }
      }

      if (typeof value === 'object') {
        return {
          ...acc,
          ...mix(value, name),
        }
      }

      return acc
    },
    {}
  )
}

const colors = {
  teal: 'rgb(6, 149, 126)',
  darkTeal: 'rgb(0, 67, 69)',
  charcoalGrey: 'rgb(55, 68, 80)',
  gunmetal: 'rgb(80, 84, 89)',
  reddishOrange: 'rgb(239, 90, 31)',

  whiteSmoke: 'rgb(241, 241, 241)',
  gainsboro: 'rgb(216, 216, 216)',
  // zeplin: #e0e0e0 with .8 opacity -> blended against white: #e6e6e6
  gainsboroLight: 'rgb(230, 230, 230)',
  gainsboroLighter: 'rgb(241, 241, 241)',

  white: 'rgb(255, 255, 255)',
  black: 'rgb(0, 0, 0)',

  orange: 'rgb(235, 90, 10)',
  darkBlue: 'rgb(5, 70, 150)',

  whuLightBlue: 'rgb(0, 165, 220)',
  whuBlue: 'rgb(5, 70, 150)',
  whuPetrol: 'rgb(0, 170, 185)',
  whuDarkPetrol: 'rgb(0, 110, 120)',
  whuEECoral: 'rgb(226, 110, 116)',
  whuEEDarkCoral: 'rgb(115, 0, 60)',
  whuEEViolet: 'rgb(130, 117, 174)',
  whuEEDarkViolet: 'rgb(56, 50, 102)',
}

export const light = mix({
  primary: colors.whuBlue,

  paper: {
    alpha: colors.whiteSmoke,
    beta: colors.gainsboro,
    gamma: colors.gainsboroLight,
    delta: colors.gainsboroLighter,
  },

  shade: colors.whuLightBlue,
  success: colors.teal,
  typography: colors.black,

  white: colors.white,
  black: colors.black,

  other: {
    alpha: colors.whuEECoral,
    beta: colors.whuEECoral,
    gamma: colors.charcoalGrey,
  },

  secondary: {
    alpha: colors.whuPetrol,
    beta: colors.whuDarkPetrol,
  },

  tertiary: {
    alpha: colors.whuEEViolet,
    beta: colors.whuEEDarkViolet,
  },

  orange: colors.orange,
  darkBlue: colors.darkBlue,
  gunmetal: colors.gunmetal,
})

export const AvailableColors = ['inherit', ...Object.keys(light)]
