import styled, { css } from 'styled-components'
import { breakpoint } from '../ThemeProvider'

export const Row = styled.div`
  display: flex;
  position: inherit !important;

  max-width: 1170px;
  margin: auto;

  justify-content: space-between;
  align-content: stretch;
`

export const AppBar = styled.div`
  background: ${(props) => props.theme.palette.white};
  z-index: 999;

  position: relative;

  ${Row} {
    height: 50px;

    ${breakpoint.lg(
      () => css`
        height: 94px;
      `
    )}
  }

  &:after {
    content: '';

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    border-bottom: 1px solid ${(props) => props.theme.palette.paperGamma};

    z-index: 999;

    pointer-events: none;

    ${breakpoint.lg(
      () => css`
        border-bottom: none;
        box-shadow: 0 2px 4px -2px rgba(0, 0, 0, 0.22);
      `
    )}
  }
`

export const RowContainer = styled.div`
  position: static !important;
`

export const LeftContainer = styled.div`
  position: relative;
  z-index: 999;

  height: 100%;
`

export const RightContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  position: inherit !important;
`
