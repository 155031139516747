import styled from 'styled-components'

export const Input = styled.input`
  font-size: ${(props) => `${props.theme.utils.inputs.fontSize}rem`};
  background: ${(props) => props.theme.palette.white};
  outline: none;
  display: block;
  width: 100%;
  border: none;

  padding: ${(props) => `
    ${props.theme.utils.inputs.padding.vertical}px
    ${props.theme.utils.inputs.padding.horizontal}px
  `};

  ::placeholder {
    color: ${(props) => props.theme.palette.other};
  }
`

export const TextInput = styled.span`
  display: ${(props) => props.theme.utils.inputs.display(props.fill)};
  align-items: center;
  position: relative;
  border-left: 6px solid ${(props) => props.theme.palette[props.color]};
`

export const After = styled.span`
  margin-left: 10px;
  flex: 0 0 auto;
`
