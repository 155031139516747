import styled, { css } from 'styled-components'
import { breakpoint } from 'ui/elements/ThemeProvider'

export const Bar = styled.div<{
  $hideMobile: boolean
}>`
  background: ${(props) => props.theme.palette['orange']};

  position: relative;
  z-index: 1000;

  ${(props) =>
    props.$hideMobile &&
    css`
      display: none;
    `}

  ${breakpoint.lg(
    () => css`
      display: block;
    `
  )}
`

export const BarInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  color: white;
  font-size: 1.75rem;

  max-width: 1170px;
  margin: auto;
  padding: 0.5em;
  width: 100%;

  ${breakpoint.xl(
    () => css`
      padding: 0.25em;
      flex-direction: row;
    `
  )};
`

export const BarText = styled.span`
  // fix helvetica offset
  padding-top: 3px;
`

export const BarButton = styled.span`
  display: block;

  background: ${(props) => props.theme.palette['gunmetal']};
  color: white;

  text-decoration: none;
  padding: 6px 12px 3px;

  margin-top: 1em;

  &:hover {
    text-decoration: underline;
  }

  ${breakpoint.xl(
    () => css`
      margin: 0 0 0 auto;
    `
  )}
`
