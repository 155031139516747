import React from 'react'
import styled from 'styled-components'

const S = {}
S.Copyright = styled.div`
  text-align: center;
  padding: 20px 0;
`

const Copyright = () => {
  const year = new Date().getFullYear()

  return (
    <S.Copyright>
      <p>© {year} WHU Executive Education</p>
    </S.Copyright>
  )
}

export default Copyright
