import _flow from 'lodash/flow'
import _get from 'lodash/get'
import _map from 'lodash/map'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { slugify as slugifySanity } from './slugify'
import { graphql, HeadFC, useStaticQuery } from 'gatsby'
import {
  GetSanityPathMapQuery,
  InternalLinkReferenceFragment,
  PersonaFragmentFragment,
} from '../generated/graphql-operations'
import { gaEvent } from '../tracking/analytics'
import { sendMatomoEvent } from '../tracking/matomo'

export type ContextI18n = {
  language: string
  languages: string[]
  defaultLanguage: string
}

export type DefaultHeadFC<DataType = object, PageContext = object> = HeadFC<
  DataType,
  PageContext & {
    language?: 'string'
    i18n: ContextI18n
  }
>
export const flattenResponse = (data: any, root?: string) => {
  const path = root ? `${root}.edges` : 'edges'

  return _flow(
    (d) => _get(d, path, []),
    (d) => _map(d, 'node')
  )(data)
}

type Member = {
  firstName: string
  lastName: string
  title?: string
  organization?: string
  position?: string
} & (
  | {}
  | {
      organization: string
      position: string
    }
)
export const buildPersonaData = (member: Member | PersonaFragmentFragment) => {
  let fullName = `${member.firstName} ${member.lastName}`
  let position = member.position

  if (member.title) {
    fullName = `${member.title} ${fullName}`
  }

  if (member.organization) {
    position = `${position} - ${member.organization}`
  }

  return { ...member, fullName, position, _type: 'faculty' }
}

type Reference = {
  _type: string | undefined | null
  _ref: string
}
type ResolvedReference = Reference & {
  _type: string | undefined | null
  slug: string | { current?: string }
}
export type SanityReference =
  | Reference
  | ResolvedReference
  | InternalLinkReferenceFragment

function generateInternalLink(reference: any, locale: string) {
  const { _type, title } = reference

  // force en locale for programs
  if (_type === 'program') {
    locale = 'en'
  }

  if (_type === 'faculty') {
    return `/the-experience/faculty/${slugifySanity(
      `${reference.firstName} ${reference.lastName}`,
      'de'
    )}`
  }

  if (!title) {
    if (process.env.NODE_ENV === 'development') {
      console.warn('Could not link to reference', reference)
    }
    return '/404'
  }

  let slug

  if (typeof title === 'string') {
    slug = slugifySanity(title, locale)
  } else {
    slug = slugifySanity(title[locale], locale)
  }

  if (_type === 'program') {
    return `/open-programs/${slug}`
  }

  if (_type === 'conference') {
    return `/conferences/${slug}`
  }

  if (_type === 'news') {
    return `/news/${slug}`
  }

  if (process.env.NODE_ENV === 'development') {
    console.warn('Could not link to reference', reference)
  }

  return '/404'
}

function normalizeReference(
  reference: ResolvedReference | InternalLinkReferenceFragment
) {
  if ('__typename' in reference) {
    if (reference.__typename === 'SanityProgram' && reference.programSlug) {
      return {
        _type: reference._type,
        slug: reference.programSlug,
      }
    }

    if (reference.__typename === 'SanityRoute' && reference.routeSlug) {
      return {
        _type: reference._type,
        slug: reference.routeSlug,
      }
    }

    if (reference.__typename === 'SanityNews' && reference.newsSlug) {
      return {
        _type: reference._type,
        slug: reference.newsSlug,
      }
    }
  }

  if ('slug' in reference && reference.slug) {
    return reference
  }

  return {
    _type: reference._type,
    slug: undefined,
    ...reference,
  }
}

// Since 'slug' WAS missing we had to dynamically create links
// https://github.com/saschabock/whu-execed-frontend/issues/25#issuecomment-655028671
// we started generating valid slugs on build-time while upgrading to gatsby v3
// this method exists for backwards compatibility
export const getInternalLink = (
  reference: ResolvedReference,
  locale: string
) => {
  if (!reference) return '/404'

  const { _type, slug } = normalizeReference(reference)

  if (_type === 'pageFile') {
    return getDownloadLink((reference as any).file)
  }

  // fallback in case we get our data from raw field
  if (!slug) {
    return generateInternalLink(reference, locale)
  }

  if (_type === 'program') {
    return `/open-programs/${slug}`
  }

  if (_type === 'conference') {
    return `/conferences/${slug}`
  }

  if (_type === 'news') {
    return `/news/${slug}`
  }

  if (_type === 'NewsTopic') {
    return `/news/${slug}`
  }

  if (slug && slug instanceof Object && slug.current) {
    return `/${slug.current}`
  }

  if (slug && typeof slug === 'string') {
    return `/${slug}`
  }

  return '/404'
}

const getPathMapQuery = graphql`
  query GetSanityPathMap {
    allSanityPathMapNode {
      nodes {
        path
        ref
      }
    }
  }
`

const useGetInternalLinkInternal = (
  reference: SanityReference | undefined,
  forceType?: string
) => {
  const {
    i18n: { language },
  } = useTranslation()
  const {
    allSanityPathMapNode: { nodes: pathMapNodes },
  } = useStaticQuery<GetSanityPathMapQuery>(getPathMapQuery)

  return useMemo(() => {
    if (!reference) {
      return '/404'
    }

    const mappedRef = pathMapNodes.find(
      (ref) => ref.ref === (reference as Reference)._ref
    )

    if (mappedRef) {
      return mappedRef.path
    }

    return getInternalLink(
      {
        ...(reference as ResolvedReference),
        _type: forceType ?? reference._type,
      },
      language
    )
  }, [reference, language, forceType])
}

const trackEvent = (category: string, action: string) => {
  gaEvent({
    category,
    action,
  })
  sendMatomoEvent(category, action)
}

export const useGetInternalLink = (
  reference: SanityReference | undefined,
  forceType?: string,
  externalLink?: string,
  trackingCategory?: string | false | null
) => {
  const link = useGetInternalLinkInternal(reference, forceType)

  const onClick = useCallback(() => {
    const _trackingCategory = trackingCategory ?? 'Click on Weblinks'
    if (_trackingCategory === false || (!link && !externalLink)) {
      return
    }

    if (!link) {
      if (externalLink) {
        trackEvent(_trackingCategory, externalLink)
      }

      return
    }

    const type = forceType ?? reference?._type

    if (type === 'pageFile') {
      trackEvent(
        _trackingCategory,
        (reference as any).file.asset.originalFilename
      )
      return
    }

    trackEvent(_trackingCategory, link)
  }, [trackingCategory, link, externalLink, reference, forceType])

  if (link?.startsWith('https://')) {
    return {
      type: 'external',
      href: link,
      onClick: onClick,
      is404: false,
    }
  }

  if ((!link || link === '/404') && !!externalLink) {
    return {
      type: 'external',
      href: externalLink,
      onClick: onClick,
      is404: false,
    }
  }

  return {
    type: 'internal',
    to: link as string,
    onClick: onClick,
    is404: link === '/404',
  }
}

export const getDownloadLink = (reference?: {
  asset: { url?: string; originalFilename: string; _ref?: string }
}) => {
  let url = reference?.asset?.url
  let filename = reference?.asset?.originalFilename

  if (!url) {
    if (!reference?.asset?._ref) {
      return undefined
    }

    const [, id, ext] = reference.asset._ref.split('-')
    url = `https://cdn.sanity.io/files/${process.env.GATSBY_SANITY_PROJECT_ID}/${process.env.GATSBY_SANITY_DATASET}/${id}.${ext}`
    filename = ''
  }

  if (filename === undefined) {
    return url
  }

  return `${url}?dl=${encodeURIComponent(filename)}`
}

type LinkedinInsightTypes = 'track'
type LinkedinInsightPayload = {
  conversion_id: number
}
export const lintrk = (
  type: LinkedinInsightTypes,
  data: LinkedinInsightPayload
): void => {
  // @ts-ignore
  if (!window || !window.lintrk) {
    return
  }

  // @ts-ignore
  window.lintrk(type, data)
}
