import { RouteUpdateArgs } from 'gatsby'
import Cookies from 'js-cookie'
import { insertScript } from './trackingHelper'

declare global {
  interface Window {
    _paq?: unknown[][]
    _mtm?: unknown[][]
  }
}

type DimensionType<T extends string> = `dimension${T}`
type MatomoDimension<T extends string> = Record<DimensionType<T>, string>

const MatomoApi = {
  setTrackerUrl: (url: string) => [url],
  setCustomUrl: (url: string) => [url],
  setSiteId: (id: string) => [id],
  setReferrerUrl: (referrer: string) => [referrer],
  setDocumentTitle: (title: string) => [title],
  trackPageView: () => [],
  enableLinkTracking: () => [],
  trackAllContentImpressions: () => [],
  trackEvent: <D extends string>(
    category: string,
    action: string,
    name?: string,
    value?: string,
    dimension?: MatomoDimension<D>
  ) => [category, action, name, value, dimension],
}
type TMatomoApi = typeof MatomoApi

let active = false
let loadedScript = false
let loadedTag = false

export const isMatomoActive = () => {
  if (process.env.NODE_ENV !== 'production') {
    return false
  }

  return active
}

export const isMatomoLoaded = () => {
  return loadedScript
}

export const isMatomoTagLoaded = () => {
  return loadedTag
}

const _loadMatomoTag = () => {
  if (!isMatomoActive() || isMatomoTagLoaded()) {
    return
  }

  loadedTag = true

  const _mtm = (window._mtm = window._mtm || [])

  _mtm.push({
    'mtm.startTime': new Date().getTime(),
    event: 'mtm.Start',
  } as any)

  insertScript('https://data.whu.edu/js/container_C1LH3dxh.js')
}

const _loadMatomo = () => {
  if (!isMatomoActive() || isMatomoLoaded()) {
    return
  }

  loadedScript = true

  const endpoint = 'https://data.whu.edu/'
  matomoApi('setTrackerUrl', endpoint + 'matomo.php')
  matomoApi('setSiteId', '5')

  insertScript(endpoint + 'matomo.js')
}

export const loadMatomo = () => {
  active = true

  _loadMatomoTag()
  _loadMatomo()
}

export const loadMatomoAndTrack = (location: Location) => {
  if (isMatomoLoaded()) {
    return
  }

  loadMatomo()
}

export const matomoApi = <TKey extends keyof TMatomoApi>(
  key: TKey,
  ...arg: Parameters<TMatomoApi[TKey]>
) => {
  if (!active) {
    return
  }

  // @ts-ignore
  const mapped = MatomoApi[key](...arg)

  const _paq = (window._paq = window._paq || [])
  _paq.push([key, ...mapped])
}

export const sendMatomoEvent = (
  ...args: Parameters<TMatomoApi['trackEvent']>
) => {
  return matomoApi('trackEvent', ...args)
}

const buildUrl = (l: Location) => l.pathname + l.search + l.hash

const sendPageView = ({
  location,
  prevLocation,
}: Pick<RouteUpdateArgs, 'location' | 'prevLocation'>) => {
  const title = document.title

  if (prevLocation) {
    matomoApi('setReferrerUrl', buildUrl(prevLocation))
  }

  matomoApi('setCustomUrl', buildUrl(location))
  matomoApi('setDocumentTitle', title)
  matomoApi('trackPageView')
  matomoApi('enableLinkTracking')
  matomoApi('trackAllContentImpressions')
}

export const trackPageView = (ref: RouteUpdateArgs) => {
  if (!isMatomoActive() || !isMatomoLoaded()) {
    return
  }

  // Add delay to ensure react-helmet can set the page title
  const delay = Math.max(32, 0)
  setTimeout(() => {
    sendPageView(ref)
  }, delay)
}
