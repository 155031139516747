import rawSlugify from 'slugify'

export function legacySlugify(string: string): string
export function legacySlugify(
  string: string | undefined | null
): string | null {
  if (!string) {
    return null
  }

  return rawSlugify(string, {
    replacement: '-',
    lower: true,
  })
}

export function slugify(
  string: string | null | undefined,
  locale: string = 'en'
): string | null {
  if (!string) {
    return null
  }

  return rawSlugify(string, {
    remove: /[^a-zA-Z0-9äöüÄÖÜß\-_\s]/g,
    replacement: '-',
    lower: true,
    locale: string.match(/[äöüß]/i) ? 'de' : locale,
  }).replace(/-+/g, '-')
}
