export const Next = (color: string = '#020203') => {
  return SvgToDataUrl(`<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 26">
      <path fill="none" stroke="${encodeURIComponent(
        color
      )}" stroke-miterlimit="10" stroke-width="3" d="M1 1l12 12L1 25"/>
    </svg>`)
}

export const SvgToDataUrl = (svg: string) => {
  return `data:image/svg+xml;utf8,${svg.replace("'", '"').replace('/ss+/', '')}`
}
