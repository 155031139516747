import React, { useMemo } from 'react'
import {
  Bar,
  BarInner,
  Content,
  ContentWrap,
  Headline,
} from './HeaderCTA.styles'
import Button from 'ui/elements/Button'
import Link from 'widgets/Link'
import { graphql } from 'gatsby'
import { HeaderCtaConfigFragment } from '../../../generated/graphql-operations'
import { useGetInternalLink } from 'utils/helpers'

type HeaderCTAConfig = HeaderCtaConfigFragment['headerCTASiteConfig']

type HeaderInfoBarProps = {
  config: HeaderCTAConfig
}
const HeaderCTA: React.FC<HeaderInfoBarProps> = ({ config: rawConfig }) => {
  const config = useMemo(() => {
    return rawConfig?.headerCTA
  }, [rawConfig])

  const link = useGetInternalLink(
    config?.ctaTarget?.reference ?? undefined,
    undefined,
    undefined,
    config?.ctaTarget?.eventCategory
  )

  if (!config?.active) {
    return null
  }

  return (
    <Bar>
      <BarInner>
        <Headline>{config.title?.localized}</Headline>
        <ContentWrap>
          <Content>{config.content?.localized}</Content>
          {!link.is404 && (
            <Button color="orange" iconName="arrow-right" as={Link} to={link}>
              {config.ctaLabel?.localized}
            </Button>
          )}
        </ContentWrap>
      </BarInner>
    </Bar>
  )
}

export const query = graphql`
  fragment HeaderCTAConfig on Query {
    headerCTASiteConfig: sanitySiteConfig {
      headerCTA {
        active
        title {
          localized(locale: $language)
        }
        content {
          localized(locale: $language)
        }
        ctaLabel {
          localized(locale: $language)
        }
        ctaTarget {
          ...InternalLink
        }
      }
    }
  }
`

export default HeaderCTA
